import { Link } from "react-router-dom";

const DonationsPage = () => {
    return (
        <div className="container text-center">
            <h1>Donationer Hall of Fame</h1>
            <p>Her kan du se alle donationer til PowerStats.</p>
            <hr />
            <table className="table table-striped table-hover">
                <thead>
                    <tr>
                        <th scope="col">Navn</th>
                        <th scope="col">Beløb</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td><Link to={"/player/76561199122348312"}>Carlo  "NarnoPjok" Højmann</Link></td>
                        <td>100 kr.</td>
                    </tr>
                    <tr>
                        <td><Link to={"/player/76561198280530766"}>Gerard "M0nkey" Harten</Link></td>
                        <td>55 kr.</td>
                    </tr>
                    <tr>
                        <td><Link to={"/player/76561197970395883"}>Lukas "dox" Dockweiler</Link></td>
                        <td>50 kr.</td>
                    </tr>
                    <tr>
                        <td>Sune Faber</td>
                        <td>25 kr.</td>
                    </tr>
                    <tr>
                        <td>Kim Andersen</td>
                        <td>25 kr.</td>
                    </tr>
                </tbody>
            </table>
            <hr />
            <p>
                PowerStats vil altid være tilgængelig for alle uden omkostninger.
                <br />
                Vi står dog over for visse udgifter i form af servere, domæner og andre nødvendigheder, som vi håber at kunne finansiere gennem donationer.
                <br />
                Hvis du ønsker at lave en anonym donation eller anvende din organisations navn, kontakt os på <a href="https://discord.gg/W54Zhj2PD3" target="_blank" rel="noreferrer">Discord</a> når du har lavet din donation.
            </p>
            <hr />
            <p>Ønsker du at støtte op om projektet, kan du donere her:</p>
            <a className="text-decoration-none" href="https://products.mobilepay.dk/box/pay-in?id=eb8fedbd-3c2c-4db7-b65f-5e5f1be50c23&phone=4618ZJ" target="_blank" rel="noreferrer">
                <button className="btn btn-primary btn-lg">Støt os på MobilePay</button>
            </a>
            <p className="mt-3">Donation på mindst 25 kr. for at blive fremhævet</p>
        </div>
    );
}

export default DonationsPage;