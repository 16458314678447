import { useCallback, useEffect, useState } from "react";
import { getUpcomingMatches } from "../services/MatchService";
import { UpcomingMatch } from "../models/UpcomingMatch";
import UpcomingMatchTable from "./UpcomingMatchTable";

const UpcomingMatches = () => {
    const [matchesByDate, setMatchesByDate] = useState<{ [date: string]: UpcomingMatch[] }>({});
    const [selectedDate, setSelectedDate] = useState<string | null>(null);

    const fetchData = async () => {
        try {
            const newMatches = await getUpcomingMatches();
            if (!newMatches || newMatches.length === 0) {
                return;
            }

            setMatchesByDate(prevMatchesByDate => {
                const updatedMatchesByDate = { ...prevMatchesByDate };
                newMatches.forEach(match => {
                    const matchDate = match.match_time.split('T')[0]; // Extract date part
                    updatedMatchesByDate[matchDate] = updatedMatchesByDate[matchDate] || [];
                    const existingMatchIndex = updatedMatchesByDate[matchDate].findIndex(m => m.challonge_id === match.challonge_id);
                    if (existingMatchIndex === -1) {
                        updatedMatchesByDate[matchDate].push(match);
                    } else {
                        // If the match already exists, update it
                        updatedMatchesByDate[matchDate][existingMatchIndex] = match;
                    }
                });
                return updatedMatchesByDate;
            });
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const getLatestDateFromDict = useCallback((dict: { [date: string]: UpcomingMatch[] }) => {
        const dates = Object.keys(dict);
        if (dates.length === 0) {
            return null;
        }

        const latestDate = dates.reduce((latest, date) => {
            return latest < date ? latest : date;
        });

        setSelectedDate(latestDate);
    }, []);

    useEffect(() => {
        getLatestDateFromDict(matchesByDate);
    }, [matchesByDate, getLatestDateFromDict]);

    useEffect(() => {
        fetchData();
    }, []);

    if (Object.keys(matchesByDate).length === 0) {
        <p className="text-center">Ingen kommende kampe</p>
    }

    return (
        <div>
            <div className="dropdown mb-1">
                <button className="btn btn-primary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    {selectedDate ? selectedDate : 'Vælg dato'}
                </button>
                <ul className="dropdown-menu">
                    {
                        Object.keys(matchesByDate).map((date, index) => (
                            <li key={index}>
                                <button className="dropdown-item" onClick={() => setSelectedDate(date)}>{date}</button>
                            </li>
                        ))
                    }
                </ul>
            </div>
            {
                selectedDate && (
                    <UpcomingMatchTable matchesForDate={matchesByDate[selectedDate]} />
                )
            }
        </div>
    );
}

export default UpcomingMatches;
