import { Link } from "react-router-dom"
import { UpcomingMatch } from "../models/UpcomingMatch"
import { FC } from "react"

interface UpcomingMatchTableProps {
    matchesForDate: UpcomingMatch[]
}

const UpcomingMatchTable: FC<UpcomingMatchTableProps> = ({ matchesForDate }) => (
    <div className="text-center">
        <table className="table">
            <thead>
                <tr>
                    <th>Hold 1</th>
                    <th></th>
                    <th>Hold 2</th>
                    <th>Tidspunkt</th>
                </tr>
            </thead>
            <tbody>

                {
                    matchesForDate.map((match, index) => (
                        <tr key={index}>
                            <td>
                                <Link to={`/team/${match.team1.teamId}`} className="text-decoration-none text-black">
                                    <img
                                        src={match.team1.teamLogo}
                                        alt={match.team1.teamName}
                                        style={{ width: 35 }}
                                        loading="lazy" />
                                    <br />
                                    {match.team1.teamName}
                                </Link>
                            </td>
                            <td>vs</td>
                            <td>
                                <Link to={`/team/${match.team2.teamId}`} className="text-decoration-none text-black">
                                    <img
                                        src={match.team2.teamLogo}
                                        alt={match.team2.teamName}
                                        style={{ width: 35 }}
                                        loading="lazy" />
                                    <br />
                                    {match.team2.teamName}
                                </Link>
                            </td>
                            <td>{match.match_time.split('T')[1]}</td>
                        </tr>
                    ))
                }
            </tbody>
        </table>
    </div>
)

export default UpcomingMatchTable