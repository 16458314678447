import { useCallback, useState } from "react";
import InfiniteScroll from 'react-infinite-scroller';
import Loading from "./Loading";
import { Match } from "../models/Match";
import { getLatestMatches } from "../services/MatchService";
import MatchTable from "./MatchTabel";

interface RecentMatchesProps {
    compact: boolean;
}

const RecentMatches: React.FC<RecentMatchesProps> = ({ compact }) => {
    const [matchesByDate, setMatchesByDate] = useState<{ [date: string]: Match[] }>({});
    const [hasMore, setHasMore] = useState(true);
    const [error, setError] = useState(false);
    const [page, setPage] = useState(0);

    const fetchData = useCallback(async () => {
        try {
            const newMatches = await getLatestMatches(page);
            if (!newMatches || newMatches.length === 0) {
                setHasMore(false);
                return;
            }
            const newMatchesWithTeams = await Promise.all(newMatches.map(async (match) => {
                const team1 = match.team1;
                const team2 = match.team2;
                return { ...match, team1, team2 };
            }));

            // Group matches by date
            const updatedMatchesByDate = { ...matchesByDate };
            newMatchesWithTeams.forEach(match => {
                const matchDate = match.match_time.split('T')[0]; // Extract date part
                updatedMatchesByDate[matchDate] = updatedMatchesByDate[matchDate] || [];
                if (updatedMatchesByDate[matchDate].find(m => m.matchid === match.matchid)) {
                    return;
                }
                updatedMatchesByDate[matchDate].push(match);
            });

            setMatchesByDate(updatedMatchesByDate);
            setPage(page + 1);
        } catch (error) {
            console.error('Error fetching data:', error);
            setError(true);
        }
    }, [matchesByDate, page]);

    return (
        <div>
            {
                error ? <div className="text-center">Der skete en fejl ved hentning af data</div> : (
                    <InfiniteScroll
                        pageStart={page}
                        loadMore={fetchData}
                        hasMore={hasMore}
                        loader={<Loading key={0} />}
                    >
                        {
                            Object.keys(matchesByDate).map((date, index) => {
                                const matchesForDate = matchesByDate[date];
                                return (
                                    <div className="text-center" key={index}>
                                        <h2>{date}</h2>
                                        <MatchTable matches={matchesForDate} compact={compact} />
                                    </div>
                                );
                            })
                        }
                    </InfiniteScroll>
                )
            }

        </div>
    );
};

export default RecentMatches;
