import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import './SearchBar.css';
import { fetchUniqueTeamsByTeamName } from "../../services/TeamService";
import { Team } from "../../models/Team";
import { Account } from "../../models/Account";
import { fetchPlayersByPlayerName } from "../../services/AccountService";
import { debounce } from 'lodash';
import SearchResults from "./SearchResults";


const SearchBar = () => {
    const navigate = useNavigate();
    const [playerResults, setPlayerResults] = useState<Account[]>([]);
    const [teamResults, setTeamResults] = useState<Team[]>([]);
    const searchBarRef = useRef<HTMLDivElement>(null);
    const inputElem = useRef<HTMLInputElement>(null);
    const handleSearch = debounce(inputVal => fetchSearchResults(inputVal), 500);

    const handleClickOutside = (event: MouseEvent) => {
        if (searchBarRef.current && !searchBarRef.current.contains(event.target as Node)) {
            inputElem.current!.value = "";
            setPlayerResults([]);
            setTeamResults([]);
        }
    };

    useEffect(() => {
        // Add event listener when the component is mounted
        document.addEventListener("mousedown", handleClickOutside);

        // Remove event listener on cleanup
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const fetchSearchResults = (query: string) => {
        if (!query) {
            setPlayerResults([]);
            setTeamResults([]);
            return;
        }
        fetchPlayers(query);
        fetchTeams(query);
    };


    const fetchPlayers = async (query: string) => {
        const player = await fetchPlayersByPlayerName(query)
        if (!player) {
            setPlayerResults([]);
            return;
        }
        setPlayerResults(player);
    };

    const fetchTeams = async (query: string) => {
        const teams = await fetchUniqueTeamsByTeamName(query)
        if (!teams) {
            setTeamResults([]);
            return;
        }
        setTeamResults(teams);
    }

    const handlePlayerClick = (steamId: string) => {
        if (inputElem.current) {
            inputElem.current.value = "";
        }
        setPlayerResults([]);
        setTeamResults([]);
        navigate(`/player/${steamId}`);
    }

    const handleTeamClick = (teamId: string) => {
        if (inputElem.current) {
            inputElem.current.value = "";
        }
        setTeamResults([]);
        setPlayerResults([]);
        navigate(`/team/${teamId}`);
    }

    return (
        <div className="search-bar-positioning m-1" ref={searchBarRef}>
            <div className="search-bar">
                <input className="form-control" ref={inputElem} type="text" placeholder="Søg" onChange={() => handleSearch(inputElem.current?.value)} />
                <i className="bx bx-search" style={{ width: 200 }}>
                    <SearchResults playerResults={playerResults} teamResults={teamResults} onPlayerClick={handlePlayerClick} onTeamClick={handleTeamClick} />
                </i >
            </div >

        </div >
    );
}

export default SearchBar;