import React from "react";
import { Link } from "react-router-dom";
import { PlayerMatch } from "../../models/PlayerMatch";
import { Team } from "../../models/Team";

interface ScoreboardProps {
    team: Team;
    players: PlayerMatch[];
}

const Scoreboard: React.FC<ScoreboardProps> = ({ team, players }) => {
    players.sort((a, b) => b.rating2 - a.rating2);

    return (
        <div className="container mt-5">
            <div className="row">
                <div className="col">
                    <Link to={`/team/${team.teamId}`} className="text-decoration-none team-name">
                        <div className="d-flex">
                            <img src={team.teamLogo} alt="TeamLogo" style={{ width: 50 }} />
                            <h2>{team.teamName}</h2>
                        </div>
                    </Link>
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Navn</th>
                                <th>K/D/A</th>
                                <th>+/-</th>
                                <th>ADR</th>
                                <th>KAST%</th>
                                <th>Rating 2.0</th>
                            </tr>
                        </thead>
                        <tbody>
                            {players.map((player, index) => (
                                <tr key={index}>
                                    <td>
                                        <Link to={`/player/${player.steamId}`} className="text-decoration-none text-black">
                                            {player.account?.playerName || player.steamId}
                                        </Link>
                                    </td>
                                    <td>{player.kills}/{player.deaths}/{player.assists}</td>
                                    <td>{player.kills - player.deaths}</td>
                                    <td>{player.adr}</td>
                                    <td>{player.kast}</td>
                                    <td>{player.rating2}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};


export default Scoreboard;