import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { PlayerStats } from '../models/PlayerStats';
import { getDivisionsBySeason, getLatestSeason, getOldestSeason } from '../services/MatchService';
import { fetchTop10Players } from '../services/PlayerStatService';
import { Link } from 'react-router-dom';
import { divisionMappings } from '../Constants';
import { LeaderboardPlayer } from '../models/LeaderboardPlayer';
import '../styles/theme.css';
import Loading from '../components/Loading';
import { AuthContext } from '../context/AuthContext';
import { PremiumBadge } from '../components/Badges/PremiumBadge';
import { DonatorBadge } from '../components/Badges/DonatorBadge';

interface StatLabels {
    [key: string]: string;
}

interface Division {
    division: string;
    formattedDivision: string;
    group: string[] | null;
}

const statLabels: StatLabels = {
    KD: 'K/D',
    hltv2Rating: 'Rating 2.0',
    ADR: 'ADR',
    HS: 'HS%',
    KAST: 'KAST%',
    openingDuelWinPercentage: 'Opening Duel Win%',
};

const getStatField = (stat: string, player: PlayerStats) => {
    switch (stat) {
        case 'KD':
            return player.kd;
        case 'hltv2Rating':
            return player.hltv2Rating;
        case 'ADR':
            return player.adr;
        case 'HS':
            return player.hs;
        case 'KAST':
            return player.kast;
        case 'openingDuelWinPercentage':
            return player.openingDuelWinPercentage;
        default:
            return '';
    }
};

const LeaderboardPage: React.FC = () => {
    const [playerStats, setPlayerStats] = useState<Record<string, LeaderboardPlayer[]>>({});
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string>('');
    const [seasons, setSeasons] = useState<number[]>([]);
    const [currentSeason, setCurrentSeason] = useState<number>(0);
    const [divisions, setDivisions] = useState<Division[]>([]);
    const [currentDivision, setCurrentDivision] = useState<string | null>(null);
    const [currentGroup, setCurrentGroup] = useState<string | null>(null);
    const { user: visitor } = useContext(AuthContext);

    // Important these matches backend model
    const statList = useMemo(() => ['KD', 'hltv2Rating', 'ADR', 'HS', 'KAST', 'openingDuelWinPercentage'], []);

    useEffect(() => {
        const loadSeasons = async () => {
            setLoading(true);
            try {
                const latest = await getLatestSeason();
                const oldest = await getOldestSeason();
                const seasonRange = Array.from({ length: latest - oldest + 1 }, (_, i) => oldest + i).sort((a, b) => b - a);
                setSeasons(seasonRange);
                setCurrentSeason(latest);
            } catch (err) {
                setError(err instanceof Error ? err.message : String(err));
            } finally {
                setLoading(false);
            }
        };
        loadSeasons();
    }, []);

    const createDivision = (divisionPrefix: string, divisionName: string, group: string | null) => {
        if (divisionName === 'Ligaen') {
            return {
                division: divisionPrefix,
                formattedDivision: divisionName,
                group: null,
            };
        }
        const splittedDiv = divisionName.split(" ", 2);
        const div = `${splittedDiv[0]} ${splittedDiv[1]}`;
        return {
            division: divisionPrefix,
            formattedDivision: div,
            group: group ? [group] : null,
        };
    };

    const loadDivisions = useCallback(async () => {
        setLoading(true);
        setError('');
        try {
            const divisions = await getDivisionsBySeason(currentSeason);
            const divisionMap = new Map();
            divisions.forEach((division) => {
                const formattedDivision = divisionMappings.get(division) || division;
                if (division === 'ligaen') {
                    divisionMap.set(division, createDivision(division, formattedDivision, null));
                }

                const divisionAndGroup = division.match(/^(\d+div)([A-Z])?$/);
                if (divisionAndGroup) {
                    const [divisionPrefix, group] = divisionAndGroup.slice(1);
                    // if division already exists, add group to group array
                    if (divisionMap.has(divisionPrefix)) {
                        const currentDivision = divisionMap.get(divisionPrefix);
                        if (currentDivision?.group) {
                            currentDivision.group.push(group);
                        } else {
                            currentDivision.group = [group];
                        }
                        divisionMap.set(divisionPrefix, currentDivision);
                    } else {
                        divisionMap.set(divisionPrefix, createDivision(divisionPrefix, formattedDivision, group));
                    }
                }
            });
            const divisionArray = Array.from(divisionMap.values());
            divisionArray.sort((a, b) => {
                if (a.division === 'ligaen') {
                    return -1;
                }
                if (b.division === 'ligaen') {
                    return 1;
                }
                return a.division.localeCompare(b.division);
            });
            setDivisions(divisionArray);
        } catch (err) {
            setError(err instanceof Error ? err.message : String(err));
        } finally {
            setLoading(false);
        }
    }, [currentSeason]);

    useEffect(() => {
        loadDivisions();
    }, [loadDivisions]);

    const fetchPlayersByStat = useCallback(async () => {
        if (!currentSeason) {
            setCurrentDivision(null);
        }
        if (!currentSeason) {
            setCurrentGroup(null);
        }
        setLoading(true);
        setError('');
        try {
            const players = await fetchTop10Players(currentSeason, visitor !== null ? visitor.steamID : null, currentDivision, currentGroup);
            setPlayerStats(players);
        }
        catch (err) {
            setError(err instanceof Error ? err.message : String(err));
        } finally {
            setLoading(false);
        }
    }, [currentSeason, currentDivision, currentGroup, visitor]);

    useEffect(() => {
        setLoading(true);
        try {
            fetchPlayersByStat()
        }
        catch (err) {
            setError(err instanceof Error ? err.message : String(err));
        }
        finally {
            setLoading(false);
        }
    }, [fetchPlayersByStat, statList]);

    const renderLeaderboards = () => {
        if (loading) {
            return <Loading />;
        }

        if (error) {
            return (
                <div className="text-center">
                    <p>Der skete en fejl ved hentning af data</p>
                </div>
            );
        }

        return (
            <div className="row">
                {statList.map((stat) => (
                    <div className="col-12 col-md-6 mb-2" key={stat}>
                        <div className="card">
                            <div className="card-header">
                                <h3>{statLabels[stat]}</h3>
                            </div>
                            <div className="card-body">
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th className='ranking' >#</th>
                                            <th>Navn</th>
                                            <th>{statLabels[stat]}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            playerStats[stat]?.map((player, index) => (
                                                <tr key={index}>
                                                    <td>{player.placement}</td>
                                                    <td>
                                                        <Link to={`/player/${player.stats.steamID}`} className='text-decoration-none text-black'>
                                                            {player.stats.account ? player.stats.account.playerName : player.stats.steamID}
                                                            {
                                                                player.stats.account && player.stats.account.premium && (
                                                                    <PremiumBadge />
                                                                )
                                                            }
                                                            {
                                                                player.stats.account && player.stats.account.donator && (
                                                                    <DonatorBadge />
                                                                )
                                                            }
                                                        </Link>
                                                    </td>
                                                    <td>{`${getStatField(stat, player.stats)}`}</td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        )
    }

    return (
        <div>
            <div className="container text-center">
                <h1>Leaderboard</h1>
                <p>Her kan du se de mest dygtige spillere for hver enkelt sæson inden for deres respektive kategorier her.</p>
                <hr />
                <div className="row justify-content-center text-center">
                    <div className="col-12 col-md-4">
                        Vælg sæson
                        <div className="dropdown mb-2">
                            <button className="btn btn-primary dropdown-toggle w-100" type="button" id="seasonDropdown" data-bs-toggle="dropdown" aria-expanded="false">
                                {currentSeason ? `Sæson ${currentSeason}` : 'Vælg en sæson'}
                            </button>
                            <ul className="dropdown-menu w-100" aria-labelledby="seasonDropdown">
                                {seasons.map((season) => (
                                    <li key={season}>
                                        <button className="dropdown-item" onClick={() => setCurrentSeason(season)}>
                                            Sæson {season}
                                        </button>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
                {
                    currentSeason !== 0 && (
                        <div className="row justify-content-center">
                            <div className="col-12 col-md-4">
                                Vælg division
                                <div className="dropdown mb-2">
                                    <button className="btn btn-primary dropdown-toggle w-100" type="button" id="seasonDropdown" data-bs-toggle="dropdown" aria-expanded="false">
                                        {currentDivision ? divisions.find((division) => division.division === currentDivision)?.formattedDivision : 'Alle'}
                                    </button>
                                    <ul className="dropdown-menu w-100" aria-labelledby="divisionDropdown">
                                        <li>
                                            <button
                                                className="dropdown-item"
                                                onClick={() => setCurrentDivision('')}
                                            >
                                                Alle
                                            </button>
                                        </li>
                                        {
                                            divisions.map((division) => (
                                                <li key={division.division}>
                                                    <button
                                                        className="dropdown-item"
                                                        onClick={() => setCurrentDivision(division.division)}
                                                    >
                                                        {division.formattedDivision}
                                                    </button>
                                                </li>
                                            ))
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    )
                }
                {
                    divisions.find((division) => division.division === currentDivision)?.group && (
                        <div className="row justify-content-center">
                            <div className="col-12 col-md-4">
                                Vælg gruppe
                                <div className="dropdown mb-2">
                                    <button className="btn btn-primary dropdown-toggle w-100" type="button" id="seasonDropdown" data-bs-toggle="dropdown" aria-expanded="false">
                                        {currentGroup ? currentGroup : 'Alle'}
                                    </button>
                                    <ul className="dropdown-menu w-100" aria-labelledby="groupDropdown">
                                        <li>
                                            <button
                                                className="dropdown-item"
                                                onClick={() => setCurrentGroup(null)}
                                            >
                                                Alle
                                            </button>
                                        </li>
                                        {
                                            divisions.find((division) => division.division === currentDivision)?.group?.map((group) => (
                                                <li key={group}>
                                                    <button
                                                        className="dropdown-item"
                                                        onClick={() => setCurrentGroup(group)}
                                                    >
                                                        {group}
                                                    </button>
                                                </li>
                                            ))
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    )
                }
            </div>
            <hr />
            {renderLeaderboards()}
        </div >
    );
};
export default LeaderboardPage;
