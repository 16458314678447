import { Link } from "react-router-dom";
import '../styles/theme.css';
import RecentMatches from "../components/RecentMatches";

const HomePage = () => {
    return (
        <div className="text-center">
            <h1>Velkommen til PowerStats</h1>
            <p>Her kan du se statistikker for alle spillere og hold i PowerLigaen fra sæson 18-25</p>
            <div>
                <a className="text-decoration-none" href="https://products.mobilepay.dk/box/pay-in?id=eb8fedbd-3c2c-4db7-b65f-5e5f1be50c23&phone=4618ZJ" target="_blank" rel="noreferrer">
                    <button className="btn btn-primary btn-lg m-3">Støt os på MobilePay</button>
                </a>
                <a className="text-decoration-none" href="https://discord.gg/W54Zhj2PD3" target="_blank" rel="noreferrer">
                    <button className="btn btn-primary btn-lg m-3">Join vores Discord</button>
                </a>
            </div>
            <hr />
            <div className="row">
                <div className="col-md-4 col-sm-6">
                    <Link className="btn btn-primary btn-lg m-3 d-block" to="/leaderboard">Leaderboard</Link>
                </div>
                <div className="col-md-4 col-sm-6">
                    <Link className="btn btn-primary btn-lg m-3 d-block" to="/lft">Free Agents</Link>
                </div>
                <div className="col-md-4 col-sm-6">
                    <Link className="btn btn-primary btn-lg m-3 d-block" to="/compare">Sammenlign</Link>
                </div>
            </div>
            <hr />
            <div className="text-center">
                <h1>Seneste resultater</h1>
                <p>Her kan du se de seneste resultater</p>
                <p>Resultater er opdateret hver 30. minut!</p>
            </div>
            <hr />
            <RecentMatches compact={false} />
        </div>
    );
};

export default HomePage;
