import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { fetchTeamByTeamIdAndSeason } from '../services/TeamService';
import { Team } from '../models/Team';
import { Match } from '../models/Match';
import { mapMappings } from '../Constants';

interface MatchTableProps {
    matches: Match[];
    compact: boolean;
}

const MatchTable: React.FC<MatchTableProps> = ({ matches, compact }) => {
    const [teams, setTeams] = useState<{ [key: string]: Team }>({});
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);



    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        }
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const fetchTeams = async (teamIds: string[]) => {
            try {
                const fetchedTeams: { [key: string]: Team } = {};
                await Promise.all(teamIds.map(async (teamId) => {
                    if (!teams[teamId]) {
                        const team = await fetchTeamByTeamIdAndSeason(teamId, matches[0].season);
                        if (team) {
                            fetchedTeams[teamId] = team;
                        }
                    }
                }));
                setTeams({ ...teams, ...fetchedTeams });
            } catch (error) {
                console.error('Error fetching teams:', error);
            }
        };

        const teamIdsToFetch: string[] = matches.reduce((ids: string[], match) => {
            if (!teams[match.team1.teamId]) {
                ids.push(match.team1.teamId);
            }
            if (!teams[match.team2.teamId]) {
                ids.push(match.team2.teamId);
            }
            return ids;
        }, []);

        if (teamIdsToFetch.length > 0) {
            fetchTeams(teamIdsToFetch);
        }
    }, [matches, teams]);

    const sortedMatches = useMemo(() => {
        return [...matches].sort((a, b) => {
            return new Date(b.match_time).getTime() - new Date(a.match_time).getTime();
        });
    }, [matches]);

    if (sortedMatches.length === 0) {
        return (
            <div className="container text-center">
                <h1>Ingen kampe fundet</h1>
            </div>
        );
    }

    return (
        <div className="text-center">

            <table className="table table-striped">
                <thead>
                    <tr>
                        {!compact && <th scope="col">Dato</th>}
                        <th scope="col">Hold 1</th>
                        <th scope="col">Score</th>
                        <th scope="col">Hold 2</th>
                        {!isMobile && <th scope="col">Map</th>}
                    </tr>
                </thead>
                <tbody>
                    {sortedMatches.map((match) => (
                        <tr key={match.matchid}>
                            {!isMobile && !compact && (
                                <td>
                                    {
                                        new Date(match.match_time).toLocaleDateString('da-DK', {
                                            day: 'numeric', // "5"
                                            month: 'long', // "marts"
                                        }) + ', ' + new Date(match.match_time).toLocaleTimeString('da-DK', {
                                            hour: '2-digit', // "20"
                                            minute: '2-digit', // "00"
                                            hour12: false // Ensure we use 24-hour format
                                        })
                                    }
                                </td>
                            )}
                            <td>
                                <Link to={`/team/${match.team1.teamId}`} className="text-decoration-none text-black">
                                    <img
                                        src={teams[match.team1.teamId]?.teamLogo}
                                        alt={teams[match.team1.teamId]?.teamName}
                                        style={{ width: 45 }}
                                        loading="lazy"
                                    />
                                    <br />
                                    {teams[match.team1.teamId]?.teamName}
                                </Link>
                            </td>
                            <td>
                                <p>
                                    <span className={match.team1won ? "text-success" : "text-danger"}>{match.team1score} </span>
                                    -
                                    <span className={match.team1won ? "text-danger" : "text-success"}> {match.team2score}</span>
                                </p>
                                <Link to={`/match/${match.matchid}`} className="btn btn-primary">Se kamp</Link>

                            </td>
                            <td>
                                <Link to={`/team/${match.team2.teamId}`} className="text-decoration-none text-black">
                                    <img
                                        src={teams[match.team2.teamId]?.teamLogo}
                                        alt={teams[match.team2.teamId]?.teamName}
                                        style={{ width: 45 }}
                                        loading="lazy" // Lazy loading of images
                                    />
                                    <br />
                                    {teams[match.team2.teamId]?.teamName}
                                </Link>
                            </td>
                            {!isMobile &&
                                <td>
                                    {
                                        match.bo3 ? 'BO3' : mapMappings.get(match.mapName) || match.mapName
                                    }
                                </td>
                            }
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default MatchTable;
