import { BrowserRouter, Routes, Route } from "react-router-dom";
import Footer from "./components/Footer";
import HomePage from "./pages/HomePage";
import Header from "./components/Header";
import ComparePage from "./pages/ComparePage";
import NotFoundPage from "./pages/NotFoundPage";
import PlayerPage from "./pages/PlayerPages/PlayerPage";
import SteamCallbackPage from "./pages/SteamCallbackPage";
import { AuthProvider } from "./context/AuthContext";
import EditPlayerPage from "./pages/PlayerPages/EditPlayerPage";
import FreeAgentPage from "./pages/FreeAgentPage/FreeAgentPage";
import TeamPage from "./pages/TeamPages/TeamPage";
import LeaderboardPage from "./pages/LeaderboardPage";
import EditTeamPage from "./pages/TeamPages/EditTeamPage";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import MatchPage from './pages/MatchPage/MatchPage';
import StandingPage from "./pages/StandingPage";
import CacheBuster from 'react-cache-buster';
import Loading from "./components/Loading";
import packageInfo from '../package.json';
import DonationsPage from "./pages/DonationsPage";
import useLocalStorage from "use-local-storage";
import MatchesPage from "./pages/MatchesPage";

function App() {
  const [isDark] = useLocalStorage<boolean>("isDark", false);
  const isProduction = process.env.NODE_ENV === 'production';
  console.log("Production:", isProduction);
  console.log("Version:", packageInfo.version);
  return (
    <div className="App" data-theme={isDark ? "dark" : "light"}>
      <CacheBuster
        currentVersion={packageInfo.version}
        isEnabled={isProduction} //If false, the library is disabled.
        isVerboseMode={false} //If true, the library writes verbose logs to console.
        loadingComponent={<Loading />} //If not pass, nothing appears at the time of new version check.
        metaFileDirectory={'.'} //If public assets are hosted somewhere other than root on your server.
      >
        <BrowserRouter>
          <AuthProvider>
            <div className="background-color">
              <Header />
              <div className="container min-vh-100">
                <Routes>

                  {["/", "/home", "/index"].map((path, index) =>
                    <Route path={path} element={<HomePage />} key={index} />
                  )}
                  <Route path="/player/:steamId/edit" element={<EditPlayerPage />} />
                  <Route path="/player/:steamId" element={<PlayerPage />} />
                  <Route path="/team/:teamId" element={<TeamPage />} />
                  <Route path="/team/:teamId/edit" element={<EditTeamPage />} />
                  <Route path="/matches" element={<MatchesPage />} />
                  <Route path="/standings" element={<StandingPage />} />
                  <Route path="/lft" element={<FreeAgentPage />} />
                  <Route path="/compare" element={<ComparePage />} />
                  <Route path="/leaderboard" element={<LeaderboardPage />} />
                  <Route path="/steam/callback" element={<SteamCallbackPage />} />
                  <Route path="/privacy" element={<PrivacyPolicy />} />
                  <Route path="/match/:matchId" element={<MatchPage />} />
                  <Route path="/donations" element={<DonationsPage />} />
                  <Route path="*" element={<NotFoundPage />} />

                </Routes>
              </div>

              <Footer />
            </div>
          </AuthProvider>
        </BrowserRouter >
      </CacheBuster>
    </div>
  );
}

export default App;