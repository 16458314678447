// MatchCard.tsx
import React from 'react';
import { Match } from '../../models/Match';

interface MatchCardProps {
    match: Match;
}

const MatchCard: React.FC<MatchCardProps> = ({ match }) => {
    const team1 = match.team1;
    const team2 = match.team2;
    return (
        <div className='card' key={match.gameid}>
            <div className='card-body text-center'>
                <div className='row'>
                    <div className='col-12 col-md-4'>
                        <h6 className='card-title text-break'>{team1.teamName}</h6>
                    </div>
                    <div className='col-12 col-md-4'>
                        <p>{match.mapName}</p>
                        <p>
                            <span className={`text-${match.team1won ? 'success' : 'danger'}`}>{match.team1score}</span>
                            -
                            <span className={`text-${match.team1won ? 'danger' : 'success'}`}>{match.team2score}</span>
                        </p>
                    </div>
                    <div className='col-12 col-md-4'>
                        <h6 className='card-title'>{team2.teamName}</h6>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MatchCard;
