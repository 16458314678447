// StatsTable.tsx
import React from 'react';
import { MapStats } from '../../../models/MapStats';
import './StatsTable.css'; // Make sure this CSS file has the styles you need

interface StatsTableProps {
    mapStats: MapStats[];
}

const StatsTable: React.FC<StatsTableProps> = ({ mapStats }) => {
    if (mapStats.length === 0) {
        return (
            <div>
                Der er ingen data at vise
            </div>
        );
    }

    return (
        <div className="container">
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th>Map</th>
                        <th>Amount Picked</th>
                        <th>Amount Banned</th>
                        <th>Amount Played</th>
                        <th>Amount Won</th>
                        <th>Chosen CT%</th>
                    </tr>
                </thead>
                <tbody>
                    {mapStats.map(stat => (
                        <tr key={stat.mapName}>
                            <td>{stat.mapName}</td>
                            <td>{stat.amountPicked}</td>
                            <td>{stat.amountBanned}</td>
                            <td>{stat.amountPlayed}</td>
                            <td>{stat.amountWon}</td>
                            <td>{stat.chosenCTPercentage}%</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default StatsTable;
