export const baseURL = process.env.REACT_APP_API_URL + "/api";

export const divisionMappings: Map<string, string> = new Map([
    ["ligaen", "Ligaen"],
    ["1div", "1 Division"],
    ["2divA", "2 Division A"],
    ["2divB", "2 Division B"],
    ["3divA", "3 Division A"],
    ["3divB", "3 Division B"],
    ["3divC", "3 Division C"],
    ["3divD", "3 Division D"],
    ["4divA", "4 Division A"],
    ["4divB", "4 Division B"],
    ["4divC", "4 Division C"],
    ["4divD", "4 Division D"],
    ["5divA", "5 Division A"],
    ["5divB", "5 Division B"]
]);

export const mapMappings: Map<string, string> = new Map([
    ["de_dust2", "Dust2"],
    ["de_inferno", "Inferno"],
    ["de_mirage", "Mirage"],
    ["de_nuke", "Nuke"],
    ["de_overpass", "Overpass"],
    ["de_vertigo", "Vertigo"],
    ["de_ancient", "Ancient"],
    ["de_anubis", "Anubis"],
]);

export const roles = ["IGL", "Entry Fragger", "Support", "AWPer", "Lurker", "Rifler"];


export const admins = [76561198269061841, 76561198125971713]