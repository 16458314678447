import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useTeamData from '../../hooks/useTeamData';
import PlayerImage from '../../components/PlayerImage';
import { getLatestSeason } from '../../services/MatchService';
import Loading from '../../components/Loading';

const EditTeamPage = () => {
    const { teamId } = useParams<{ teamId: string }>();
    const [currentSeason, setCurrentSeason] = useState<number>(0);
    const { teamData, players, loading } = useTeamData(teamId, currentSeason);

    // Load available seasons
    useEffect(() => {
        const loadSeasons = async () => {
            try {
                const latest = await getLatestSeason();
                setCurrentSeason(latest);
            } catch (err) {
                console.log(err);
            }
        };
        loadSeasons();
    }, []);

    if (loading || !teamData) {
        return (
            <div className="container text-center">
                <Loading />
            </div>
        );
    }

    function removePlayer(steamID: string): void {
        console.log(steamID);
    }

    return (
        <React.Fragment>
            <h3>Rediger hold</h3>
            <hr />
            <h2>Holdejer</h2>
            <div className="mb-3">
                <div className="input-group">
                    <span className="input-group-text" id="basic-addon3">https://steamcommunity.com/profiles/</span>
                    <input type="text" className="form-control" id="owner-id" aria-describedby="basic-addon3" defaultValue={teamData.ownerId} />
                </div>
            </div>
            <hr />
            <h2>Spillere</h2>
            <div className='row'>
                {
                    players.length > 0 ? (
                        players.map((player) => (
                            <div className='col-3' key={player.steamID}>
                                <div className='card'>
                                    <button type="button" className="btn-danger" onClick={() => removePlayer(player.steamID)}>X</button>
                                    <div className='card-body'>
                                        <PlayerImage id={player.steamID} />
                                        <h5 className='card-title'>{player.account ? player.account.name : player.steamID}</h5>
                                    </div>
                                </div>
                            </div>
                        ))
                    ) : (
                        <div className="col-12">
                            <h3>Holdet har ingen spillere</h3>
                        </div>
                    )
                }
            </div>
            <hr />

            <div className='mb-3'>
                <button type="button" className="btn btn-primary">Gem ændringer</button>
            </div>

        </React.Fragment>
    );
};

export default EditTeamPage;