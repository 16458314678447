import axios from "axios";
import { Team } from "../models/Team";
import { Standing } from "../models/Standing";
import { baseURL } from "../Constants";

const url = `${baseURL}/Team`;

export const fetchTeamByTeamIdAndSeason = async (teamId: string, season: number): Promise<Team | null> => {
    try {
        const response = await axios.get(`${url}/getTeamByTeamIdAndSeason`, {
            params: {
                teamId,
                season
            }
        });
        return response.data;
    }
    catch (error) {
        throw error;
    }
}

export const fetchTeamsByTeamName = async (teamName: string): Promise<Team[] | undefined> => {
    try {
        const response = await axios.get(`${url}/getTeamsByTeamName/${teamName}`);
        return response.data;
    }
    catch (error) {
        throw error;
    }
}

export const fetchTeamByTeamId = async (teamId: string): Promise<Team> => {
    try {
        const response = await axios.get(`${url}/getTeamByTeamId/${teamId}`);
        return response.data;
    }
    catch (error) {
        throw error;
    }
}

export const fetchUniqueTeamsByTeamName = async (teamName: string): Promise<Team[]> => {
    try {
        const response = await axios.get(`${url}/getUniqueTeamsByName/${teamName}`);
        return response.data;
    }
    catch (error) {
        throw error;
    }
}

export const fetchSeasonsPlayed = async (teamId: string): Promise<number[]> => {
    try {
        const response = await axios.get(`${url}/getSeasonsPlayed/${teamId}`);
        return response.data;
    }
    catch (error) {
        throw error;
    }
}

export const fetchTeamStanding = async (teamId: string, season: number): Promise<Standing> => {
    try {
        const response = await axios.get(`${url}/getTeamStanding`, {
            params: {
                teamId,
                season
            }
        });
        return response.data;
    }
    catch (error) {
        throw error;
    }
}

export const updateTeamOwner = async (teamId: string, steamId: string): Promise<boolean> => {
    try {
        const response = await axios.post(`${url}/updateTeamOwner?teamId=${teamId}&steamId=${steamId}`);
        return response.data;
    }
    catch (error) {
        throw error;
    }
}

export const fetchTeamByTeamNameAndSeason = async (teamName: string, season: number): Promise<Team | null> => {
    try {
        const params = new URLSearchParams();
        params.append('teamName', teamName);
        params.append('season', season.toString());

        const response = await axios.get(`${url}/getTeamByTeamNameAndSeason`, {
            params
        });
        return response.data;
    }
    catch (error) {
        throw error;
    }
}