import { useEffect, useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";

const SteamCallbackPage: React.FC = () => {
    const { setToken } = useContext(AuthContext);
    const navigate = useNavigate();



    useEffect(() => {
        const searchParams = window.location.search;
        const params = new URLSearchParams(searchParams);
        const receivedToken = params.get("token");
        const token = receivedToken?.replace(/ /g, "+");
        if (!token) {
            alert("Modtog ikke et gyldigt token fra serveren");
            navigate("/");
            return;
        }

        const setTokenAndNavigate = async () => {
            await setToken(token);
            navigate("/");
        }
        setTokenAndNavigate();

    }, [setToken, navigate]);

    return (
        <div className="text-center">
            <h1>Steam Login</h1>
            <p>Redirecting...</p>
        </div>
    );
};

export default SteamCallbackPage;

