import WbSunnyIcon from '@mui/icons-material/WbSunny';
import DarkModeIcon from '@mui/icons-material/DarkMode';

type ToogleProps = {
    isChecked: boolean;
    handleChange: (tab: boolean) => void;
}

const Toggle: React.FC<ToogleProps> = ({ handleChange, isChecked }) => {
    return (
        <button className="btn btn-primary switcher" onClick={() => handleChange(!isChecked)}
        >
            {
                isChecked ? <DarkModeIcon /> : <WbSunnyIcon />
            }
        </button>
    );
}

export default Toggle;