import { Suspense, useCallback, useContext, useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { fetchSeasonsPlayed, fetchTeamStanding, updateTeamOwner } from "../../services/TeamService";
import PlayerImage from "../../components/PlayerImage";
import { admins, divisionMappings } from "../../Constants";
import useTeamData from "../../hooks/useTeamData";
import '../../styles/theme.css';
import SeasonScroll from "../../components/SeasonScroll/SeasonScroll";
import TeamTabs from "./TeamTabs";
import TeamPlayers from "./TeamPlayers";
import TeamMatches from "./TeamMatches";
import { Standing } from "../../models/Standing";
import { Account } from "../../models/Account";
import { fetchAccount } from "../../services/AccountService";
import Loading from "../../components/Loading";
import { AuthContext } from "../../context/AuthContext";
import TeamMapStats from "./TeamMapStats";

const TeamPage = () => {
    const { teamId } = useParams<{ teamId: string }>();
    const [season, setSeason] = useState<number>(0);
    const [seasons, setSeasons] = useState<number[]>();
    const [owner, setOwner] = useState<Account | null>(null);
    const [standing, setStanding] = useState<Standing>();
    const { teamData, players, loading } = useTeamData(teamId, season);
    const [activeTab, setActiveTab] = useState<string>();
    const [newOwner, setNewOwner] = useState<string>("");
    const { user: visitor } = useContext(AuthContext);
    const isLoading = loading || !teamData || !seasons;

    const getTeamStanding = useCallback(async () => {
        if (!teamId) return;
        if (season === 0) return;
        try {
            const teamStanding = await fetchTeamStanding(teamId, season);
            setStanding(teamStanding);
        } catch (error) {
            console.error(error);
        }
    }, [teamId, season]);

    const getSeasonsPlayed = useCallback(async () => {
        if (!teamId) return;
        try {
            const seasons = await fetchSeasonsPlayed(teamId);
            setSeasons(seasons);
            if (seasons.length > 0) setSeason(Math.max(...seasons));
        } catch (error) {
            console.error(error);
        }
    }, [teamId]);

    useEffect(() => {
        const getOwner = async () => {
            if (!teamData?.ownerId) {
                setOwner(null);
                return;
            }
            try {
                const owner = await fetchAccount(teamData.ownerId);
                if (!owner) throw new Error('No owner found');
                setOwner(owner);
            } catch (error) {
                console.error(error);
                setOwner(null);
            }
        };
        getOwner();
    }, [teamData]);

    useEffect(() => {
        getTeamStanding();
    }, [getTeamStanding]);


    useEffect(() => {
        getSeasonsPlayed();
    }, [getSeasonsPlayed]);

    useEffect(() => {
        if (activeTab === undefined) {
            setActiveTab('players');
        }
    }, [activeTab]);

    const handleSeasonChange = useCallback((selectedSeason: number) => {
        setSeason(selectedSeason);
        setActiveTab('players');
    }, [setSeason]);

    const handleTabChange = (tabId: string) => {
        setActiveTab(tabId);
    };

    const handleOwnerChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setNewOwner(e.target.value);
    }

    const handleNewOwner = async () => {
        if (!newOwner || !teamData) return;
        try {
            const res = await updateTeamOwner(teamData.teamId, newOwner);
            if (!res) {
                alert('Der skete en fejl');
                return;
            };
            const account = await fetchAccount(newOwner);
            if (!account) {
                alert('Der skete en fejl');
                return;
            }
            setOwner(account);
        } catch (error) {
            console.error(error);
        }
    }

    if (isLoading) {
        return (
            <div className="container text-center">
                <Loading />
            </div>
        );
    }

    return (
        <div className="container">
            <div className="text-end">
                {
                    (visitor && visitor.steamID === owner?.steamID) && (
                        <Link to={`/team/${teamId}/edit`} className="btn btn-primary">Rediger hold</Link>
                    )
                }
            </div>
            <SeasonScroll seasons={seasons} selectedSeason={season} onSeasonChange={handleSeasonChange} />
            <hr />
            <div className="text-center mt-5 mb-2">
                <div className="row">
                    <div className="col-7">
                        <h1>{teamData.teamName}</h1>
                        <img src={teamData.teamLogo} alt={teamData.teamName} style={{ width: 200 }} />
                        <h4>{divisionMappings.get(teamData.division)}</h4>
                        {
                            standing && (
                                <div>
                                    <h5>Placering: {standing.rank}</h5>
                                </div>
                            )
                        }
                    </div>
                    <div className="col-5 mt-2">
                        <h2>Holdejer</h2>
                        {
                            owner ? (
                                <div className="card">
                                    <div className="card-body">
                                        <Link to={`/player/${owner.steamID}`} className="text-decoration-none text-black">
                                            <PlayerImage id={owner.steamID} width={90} />
                                            <h5 className="card-title text-center">{owner.playerName}</h5>
                                        </Link>
                                    </div>
                                </div>
                            ) : (
                                <div className="text-center">
                                    <p>
                                        Der er ingen holdejer på holdet.
                                        Skriv til <a href="https://twitter.com/RealSoerensen" target="_blank" rel="noreferrer">Soerensen</a> fra holdets twitter konto for at tilføje en holdejer.
                                    </p>
                                </div>
                            )
                        }
                        {
                            visitor && admins.find((admin) => admin === Number(visitor.steamID)) && (
                                <div>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={newOwner}
                                        onChange={(e) => handleOwnerChange(e)}
                                    />
                                    <button
                                        className="btn btn-primary"
                                        onClick={handleNewOwner}
                                    >
                                        Gem
                                    </button>
                                </div>
                            )
                        }

                    </div>
                </div>
            </div>
            <hr />
            <TeamTabs onTabChange={handleTabChange} />
            <hr />
            {
                activeTab === 'players' && (
                    <Suspense fallback={<div>Loading...</div>} >
                        <TeamPlayers players={players} />
                    </Suspense>
                )
            }
            {
                activeTab === 'matches' && (
                    <Suspense fallback={<div>Loading...</div>} >
                        <TeamMatches season={season} />
                    </Suspense>
                )
            }
            {
                activeTab === 'veto' && (
                    <Suspense fallback={<div>Loading...</div>} >
                        <TeamMapStats season={season} />
                    </Suspense>
                )
            }
        </div>
    );
}

export default TeamPage;

