import { createContext, useState, useEffect } from 'react';
import { Account } from '../models/Account';
import { jwtDecode } from 'jwt-decode';
import posthog from 'posthog-js';

interface AuthContextType {
    user: Account | null;
    token: string | null;
    setToken: React.Dispatch<React.SetStateAction<string | null>>;
}

export const AuthContext = createContext<AuthContextType>({
    user: null,
    token: null,
    setToken: () => { },
});

interface AuthProviderProps {
    children: React.ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
    const [token, setToken] = useState<string | null>(() => {
        // Retrieve the token from sessioStorage when the component mounts
        return localStorage.getItem('token');
    });
    const [user, setUser] = useState<Account | null>(null);
    // Update the token in session storage whenever it changes
    useEffect(() => {
        if (token) {
            localStorage.setItem('token', token);
            try {
                const decodedToken: any = jwtDecode(token);
                const expirationTime = decodedToken.expires * 1000; // Convert expiration time to milliseconds
                const currentTime = Date.now();

                if (expirationTime < currentTime) {
                    // Token is expired, attempt to refresh it
                    alert('Login udløbet, log venligst ind igen');
                    setUser(null);
                    setToken(null);
                }
                posthog.identify(decodedToken.steamID, decodedToken);
                setUser(decodedToken);
            } catch (error) {
                console.error('Error decoding token:', error);
                setUser(null);
                setToken(null);
            }
        } else {
            localStorage.removeItem('token');
            posthog.reset();
            setUser(null);
        }
    }, [token]);

    return (
        <AuthContext.Provider value={{ setToken, user, token }}>
            {children}
        </AuthContext.Provider>
    );
}