import { useState } from "react";
import RecentMatches from "../components/RecentMatches";
import MatchTabs from "../components/MatchTabs";
import UpcomingMatches from "../components/UpcomingMatches";

const MatchesPage = () => {
    const [activeTab, setActiveTab] = useState<String>('upcoming');

    return (
        <div>
            <div className="text-center">
                <h1>Kampe</h1>
                <p>Se kommende kampe og resultater fra tidligere kampe</p>
            </div>
            <hr />
            <MatchTabs onTabChange={setActiveTab} />
            <hr />


            {
                activeTab === 'upcoming' && (
                    <div>
                        <UpcomingMatches />
                    </div>
                )

            }
            {
                activeTab === 'results' && (
                    <div>
                        <RecentMatches compact={true} />
                    </div>
                )
            }
        </div>
    );
}

export default MatchesPage;