import { useState, useEffect, useCallback } from "react";
import { Standing } from "../models/Standing";
import { getDivisionStandings, getDivisionsBySeason, getLatestSeason, getOldestSeason } from "../services/MatchService";
import { Link } from "react-router-dom";
import { divisionMappings } from "../Constants";
import Loading from "../components/Loading";

interface DivisionStandings {
    [division: string]: Standing[];
}

const StandingPage = () => {
    const [standings, setStandings] = useState<DivisionStandings>({});
    const [currentSeason, setCurrentSeason] = useState<number>(0);
    const [seasons, setSeasons] = useState<number[]>([]);
    const [divisions, setDivisions] = useState<{ division: string, formattedDivision: string }[]>([]);
    const [currentDivision, setCurrentDivision] = useState<string>("");
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const loadSeasons = async () => {
            setLoading(true);
            try {
                const latest = await getLatestSeason();
                const oldest = await getOldestSeason();
                const seasonRange = Array.from({ length: latest - oldest + 1 }, (_, i) => oldest + i).sort((a, b) => b - a);
                setSeasons(seasonRange);
                setCurrentSeason(latest);
            } catch (err) {
                setError(err instanceof Error ? err.message : String(err));
            } finally {
                setLoading(false);
            }
        };
        loadSeasons();
    }, []);

    const createDivision = useCallback((divisionPrefix: string, divisionName: string) => {
        const isLigaen = divisionName === 'Ligaen';
        const formattedDivision = isLigaen ? divisionName : divisionName.split(" ", 2).join(" ");
        return {
            division: divisionPrefix,
            formattedDivision
        };
    }, []);

    useEffect(() => {
        const fetchStandings = async () => {
            setLoading(true);
            try {
                const standingsData = await getDivisionStandings(currentSeason, currentDivision);
                setStandings(standingsData as DivisionStandings);
            } catch (err) {
                setError(err instanceof Error ? err.message : String(err));
            } finally {
                setLoading(false);
            }
        };

        fetchStandings();
    }, [currentDivision, currentSeason]);

    useEffect(() => {
        const loadDivisions = async () => {
            setLoading(true);
            try {
                const divisions = await getDivisionsBySeason(currentSeason);
                const divisionMap = new Map();
                divisions.forEach((division) => {
                    const formattedDivision = divisionMappings.get(division) || division;
                    if (division === 'ligaen') {
                        divisionMap.set(division, createDivision(division, formattedDivision));
                    }

                    const divisionAndGroup = division.match(/^(\d+div)([A-Z])?$/);
                    if (divisionAndGroup) {
                        const [divisionPrefix] = divisionAndGroup.slice(1);
                        divisionMap.set(divisionPrefix, createDivision(divisionPrefix, formattedDivision));
                    }
                });
                const divisionArray = Array.from(divisionMap.values());
                divisionArray.sort((a, b) => {
                    if (a.division === 'ligaen') {
                        return -1;
                    }
                    if (b.division === 'ligaen') {
                        return 1;
                    }
                    return a.division.localeCompare(b.division);
                });
                setDivisions(divisionArray);
            } catch (err) {
                setError(err instanceof Error ? err.message : String(err));
            } finally {
                setLoading(false);
            }
        };
        loadDivisions();
    }, [createDivision, currentSeason]);

    const getStandingColor = (division: string, index: number) => {
        if (currentSeason === 24) {
            let color = "white";
            if (division === "ligaen") {
                if (index < 4) color = "green";
                else if (index === 6) color = "orange";
                else if (index === 7) color = "red";
            } else if (division.endsWith("div")) {
                if (index === 0) color = "green";
                else if (index === 1) color = "yellow";
                else if (index === 6 || index === 7) color = "orange";
                else if (index === 8 || index === 9) color = "red";
            } else if (String(division).startsWith("2div") || String(division).startsWith("3div") || String(division).startsWith("4div")) {
                if (index === 0) color = "green";
                else if (index >= 1 && index <= 2) color = "yellow";
                else if (index >= 5 && index <= 7) color = "orange";
                else if (index >= 8 && index <= 9) color = "red";
            } else if (String(division).startsWith("5div")) {
                if (index === 0) color = "green";
                else if (index >= 1 && index <= 2) color = "yellow";
            }
            return color;
        }

        let color = "white";
        if (division === "ligaen") {
            if (index < 4) color = "green";
            else if (index === 6) color = "orange";
            else if (index === 7) color = "red";
        } else if (String(division).startsWith("2div") || division.endsWith("div")) {
            if (index === 0) color = "green";
            else if (index === 1) color = "yellow";
            else if (index === 6 || index === 7) color = "orange";
            else if (index === 8 || index === 9) color = "red";
        } else if (String(division).startsWith("3div")) {
            if (index === 0) color = "green";
            else if (index >= 1 && index <= 2) color = "yellow";
            else if (index >= 5 && index <= 7) color = "orange";
            else if (index >= 8 && index <= 9) color = "red";
        } else if (String(division).startsWith("4div")) {
            if (index === 0) color = "green";
            else if (index >= 1 && index <= 3) color = "yellow";
        }
        return color;
    }


    const renderStandings = () => {
        if (loading) {
            return (
                <Loading />
            );
        }

        if (error) {
            return (
                <div className="text-center">
                    <h1>Der skete en fejl</h1>
                    <p>{error}</p>
                </div>
            );
        }

        return (
            <div className="row">
                {Object.entries(standings).map(([division, divisionStandings]) => (
                    <div key={division} className="col-12 col-sm-12 col-md-6">
                        {divisionStandings && (
                            <>
                                <h2 className="text-center">{divisionMappings.get(division)}</h2>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th className="ranking">#</th>
                                            <th>Hold</th>
                                            <th>Kampe - (W/L)</th>
                                            <th>Points</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {divisionStandings.map((standing, index) => {
                                            let color = getStandingColor(division, index);
                                            return (
                                                <tr key={standing.team.teamId}>
                                                    <td style={{ backgroundColor: color }}>{standing.rank}</td>
                                                    <td style={{ backgroundColor: color }}>
                                                        <Link to={`/team/${standing.team.teamId}`} className="text-decoration-none text-black">
                                                            <img src={standing.team.teamLogo} alt={standing.team.teamName} style={{ width: 25 }} />
                                                            {standing.team.teamName}
                                                        </Link>
                                                    </td>
                                                    <td style={{ backgroundColor: color }}>{standing.wins}/{(standing.gamesPlayed - standing.wins)}</td>
                                                    <td style={{ backgroundColor: color }}>{standing.points}</td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table >
                            </>
                        )}
                    </div >
                ))}
            </div >
        );
    }


    return (
        <div>
            <div className="text-center">
                <h1>Standings</h1>
                <p>Her kan du se stillingen i de forskellige divisioner</p>
            </div>
            <hr />
            <div className="row justify-content-center text-center">
                <div className="col-12 col-md-4">
                    Vælg sæson
                    <div className="dropdown mb-2">
                        <button className="btn btn-primary dropdown-toggle w-100" type="button" id="seasonDropdown" data-bs-toggle="dropdown" aria-expanded="false">
                            Sæson {currentSeason}
                        </button>
                        <ul className="dropdown-menu text-center w-100" aria-labelledby="seasonDropdown">
                            {seasons.map((season) => (
                                <li key={season}>
                                    <button
                                        className="dropdown-item"
                                        onClick={() => setCurrentSeason(season)}
                                    >
                                        Sæson {season}
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center text-center">
                <div className="col-12 col-md-4">
                    Vælg division
                    <div className="dropdown mb-2">
                        <button className="btn btn-primary dropdown-toggle w-100" type="button" id="divisionDropdown" data-bs-toggle="dropdown" aria-expanded="false">
                            {currentDivision ? divisions.find((division) => division.division === currentDivision)?.formattedDivision : 'Alle'}
                        </button>
                        <ul className="dropdown-menu text-center w-100" aria-labelledby="divisionDropdown">
                            <li>
                                <button
                                    className="dropdown-item"
                                    onClick={() => setCurrentDivision('')}
                                >
                                    Alle
                                </button>
                            </li>
                            {divisions.map((division) => (
                                <li key={division.division}>
                                    <button
                                        className="dropdown-item"
                                        onClick={() => setCurrentDivision(division.division)}
                                    >
                                        {division.formattedDivision}
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
            {renderStandings()}
        </div>
    );
};

export default StandingPage;
