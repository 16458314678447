import React, { useCallback, useState } from "react"
import { Team } from "../models/Team";
import { Link } from "react-router-dom";
import PlayerImage from "./PlayerImage";
import { getPlayersAvgRating } from "../services/MatchService";
import { PlayerMatch } from "../models/PlayerMatch";

interface TeamLineupProps {
    matchId: string;
    team: Team;
    players: PlayerMatch[];
}

const TeamLineup: React.FC<TeamLineupProps> = ({ matchId, team, players }) => {
    const [avgPlayers, setAvgPlayers] = useState<{ [steamId: string]: number }>({});

    const fetchAvgPlayers = useCallback(async () => {
        try {
            if (!matchId) throw new Error('No matchId provided');
            const avgPlayers = await getPlayersAvgRating(matchId);
            setAvgPlayers(avgPlayers);
        }
        catch (error) {
            console.error(error);
        }
    }, [matchId]);

    useState(() => {
        fetchAvgPlayers();
    });

    return (
        <div className="text-center">
            <h2><img src={team.teamLogo} alt="Team logo" className="img-fluid" style={{ width: 35 }} />{team.teamName}</h2>
            <div className="row justify-content-center mx-auto">
                {players.map((player) => (
                    <div className="col-6 col-md-2" key={player.steamId}>
                        <Link to={`/player/${player.steamId}`} className="text-decoration-none">
                            <div className="card">
                                <div className="card-body text-center">
                                    <PlayerImage id={player.steamId} width={75} />
                                    <p>{player.account ? player.account.playerName : player.steamId}</p>
                                    <p>AVG Rating: {Math.round(avgPlayers[player.steamId] * 100) / 100}</p>
                                </div>
                            </div>
                        </Link>
                    </div>
                ))}
            </div>
        </div>

    )
}

export default TeamLineup;